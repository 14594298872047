'use client'

import { Result } from 'antd'
import Button from 'antd/lib/button'
import Link from 'next/link'

import styles from './error-page.styles.module.css'

const RESOURCES = {
  buttonText: 'Go home page',
  subTitleText: 'Sorry, something went wrong',
}

export default function GlobalError() {
  return (
    <html>
      <body>
        <div className={styles.container}>
          <Result
            extra={
              <Button>
                <Link href="/">{RESOURCES.buttonText}</Link>
              </Button>
            }
            status={500}
            subTitle={RESOURCES.subTitleText}
            title="500"
          />
        </div>
      </body>
    </html>
  )
}

// interface GlobalErrorProps {
//   error: Error & { digest?: string }
//   reset: () => void
// }
